import { createSlice } from '@reduxjs/toolkit';
import {
  changePasswordBuilder,
  createInfluencerRequestPasswordBuilder,
  fetchUserEarningsBuilder,
  fetchUserInfoBuilder,
  loginBuilder,
  redeemInvitationCodeBuilder,
  updateBasicInfoBuilder,
  uploadAudioBuilder,
  uploadPhotoBuilder,
  uploadVideoBuilder,
  verifyEmailBuilder,
} from './builder';
import { LOGOUT, UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';

const INIT_STATE = {
  user: null,
  pending: {
    fetchUserInfo: false,
    changePassword: false,
    fetchUserEarnings: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    updateNumOfUpdatedMedia: (state, { payload }) => {
      const { numOfMedia, type } = payload;
      if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO) {
        state.user.numOfUploadedPhotos += numOfMedia;
      } else if (type === UPLOAD_FILE_CONSTRAINTS.TYPE.VIDEO) {
        state.user.numOfUploadedVideos += numOfMedia;
      } else {
        state.user.numOfUploadedAudios += numOfMedia;
      }
    },
    setUserStatus: (state, { payload }) => {
      state.user.status = payload;
    },
  },
  extraReducers: builder => {
    fetchUserInfoBuilder(builder);
    loginBuilder(builder);
    changePasswordBuilder(builder);
    verifyEmailBuilder(builder);
    createInfluencerRequestPasswordBuilder(builder);
    redeemInvitationCodeBuilder(builder);
    updateBasicInfoBuilder(builder);
    uploadPhotoBuilder(builder);
    uploadVideoBuilder(builder);
    uploadAudioBuilder(builder);
    fetchUserEarningsBuilder(builder);
    builder.addCase(LOGOUT, () => ({ ...INIT_STATE }));
  },
});

export const { updateNumOfUpdatedMedia, setUserStatus } = userSlice.actions;

export const selectUser = state => state.userInfo.user;
export const selectUserActionsPending = state => state.userInfo.pending;

export default userSlice.reducer;
