import React, { useEffect, useState } from 'react';
import {
  AnalyticsContainer,
  ChartContainer,
  ChartContent,
  ChartHeader,
  Content,
  Line,
  PageContainer,
  SpinnerContainer,
  StatisticContainer,
} from './HomePage.styles';
import { Text18Bold, Text20Bold } from '../../components/utils/Text/Text.styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, selectUserActionsPending } from '../../store/slices/user/slice';
import { AnalyticsCard, DateRangePickerComponent, HomeBarChart, Spinner, StatisticCard } from '../../components';
import { ReactComponent as WalletIcon } from '../../assets/icons/wallet.svg';
import { ReactComponent as SafeIcon } from '../../assets/icons/safe.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card-down.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { ReactComponent as VideoRecorderIcon } from '../../assets/icons/video-recorder.svg';
import { ReactComponent as SaleIcon } from '../../assets/icons/sale.svg';
import { ReactComponent as FilmIcon } from '../../assets/icons/film.svg';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../utils/constants';
import { endOfDay, subDays } from 'date-fns';
import { fetchUserEarnings } from '../../store/slices/user/asyncThunks';

const HomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { fetchUserEarnings: fetchUserEarningsPending } = useSelector(selectUserActionsPending);
  const [range, setRange] = useState([subDays(new Date(), 6), endOfDay(new Date())]);
  const { earnings, daily, overview } = user.analytics;

  useEffect(() => {
    dispatch(fetchUserEarnings({ startDate: range[0].toISOString(), endDate: range[1].toISOString() }));
  }, [range, dispatch]);

  return (
    <PageContainer>
      <Text20Bold>{`Welcome, ${user.name}!`}</Text20Bold>
      <Content>
        <StatisticContainer>
          <StatisticCard
            title="Available Balance"
            icon={<CreditCardIcon />}
            value={(earnings.available * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
          <StatisticCard
            title="Total Balance"
            icon={<WalletIcon color="#BC9EFF" />}
            value={(earnings.balance * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
          <StatisticCard
            title="Lifetime Proceeds"
            icon={<SafeIcon />}
            value={(earnings.lifetime * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}
            valuePrefix="$"
          />
        </StatisticContainer>
        <ChartContainer>
          <ChartHeader>
            <Text18Bold>Overview</Text18Bold>
            <DateRangePickerComponent value={range} setValue={setRange} />
          </ChartHeader>
          {daily && overview && !fetchUserEarningsPending ? (
            <ChartContent>
              <HomeBarChart dailyAnalytics={daily} />
              <Line />
              <AnalyticsContainer>
                <AnalyticsCard icon={<ImageIcon />} title="Photo Sales" earnings={overview.photo.earnings} />
                <AnalyticsCard icon={<VideoRecorderIcon />} title="Video Sales" earnings={overview.video.earnings} />
                <AnalyticsCard icon={<FilmIcon />} title="Bundle Sales" earnings={overview.bundle.earnings} />
                <AnalyticsCard icon={<SaleIcon />} title="Other Sales" earnings={overview.other.earnings} />
              </AnalyticsContainer>
            </ChartContent>
          ) : (
            <SpinnerContainer>
              <Spinner width={100} height={100} />
            </SpinnerContainer>
          )}
        </ChartContainer>
      </Content>
    </PageContainer>
  );
};

export default HomePage;
