import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Container } from 'rsuite';

const HomeBarChart = ({ dailyAnalytics }) => {
  const data = useMemo(() => {
    const days = Object.keys(dailyAnalytics);

    return days.map(day => {
      const date = parseISO(day);
      const dayOfWeek = format(date, 'd MMM yy');

      const photos = dailyAnalytics[day].photo.earnings * VALUE_OF_ONE_STAR_IN_USD;
      const videos = dailyAnalytics[day].video.earnings * VALUE_OF_ONE_STAR_IN_USD;
      const bundles = dailyAnalytics[day].bundle.earnings * VALUE_OF_ONE_STAR_IN_USD;
      const other = dailyAnalytics[day].other.earnings * VALUE_OF_ONE_STAR_IN_USD;
      return {
        name: dayOfWeek,
        data: [photos, videos, bundles, other],
      };
    });
  }, [dailyAnalytics]);

  const colors = ['#DDCEFF', '#AD88EF', '#7E4FD0', '#572E9F'];

  const options = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lexend',
      },
    },
    title: {
      text: '',
    },
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: '#fff',
        fontSize: 12,
      },
    },
    xAxis: {
      categories: data.map(item => item.name),
      min: 0,
      max: data.length > 6 ? 6 : data.length - 1,
      scrollbar: {
        enabled: true,

        height: 10,
        trackBorderColor: '#1F1632',
        barBorderColor: '#1F1632',
        barBackgroundColor: '#1F1632',

        trackBackgroundColor: 'transparent',
      },
      labels: {
        style: {
          color: '#fff',
          fontSize: 11,
        },
      },
      gridLineColor: '#1F1632',
      gridLineDashStyle: 'Dot',
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: '',
      },

      gridLineColor: '#1F1632',
      gridLineDashStyle: 'Dot',
      labels: {
        formatter: function ({ value }) {
          return `$${value}`;
        },
        style: {
          color: '#fff',
          fontSize: 12,
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        borderWidth: 0,
        borderRadius: 5,
        groupPadding: 0,
      },
    },
    colors: colors,
    tooltip: {
      valueDecimals: 2,
      style: {
        fontSize: 12,
      },
    },
    series: [
      {
        name: 'Photos',
        data: data.map(item => item.data[0]),
      },
      {
        name: 'Videos',
        data: data.map(item => item.data[1]),
      },
      {
        name: 'Bundles',
        data: data.map(item => item.data[2]),
      },
      {
        name: 'Other',
        data: data.map(item => item.data[3]),
      },
    ],
  };

  return (
    <Container>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Container>
  );
};

export default HomeBarChart;
