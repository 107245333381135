import styled from 'styled-components';
import { Text14Light } from '../utils/Text/Text.styles';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${({ $hasHeader }) => $hasHeader && '13rem'};
  background: linear-gradient(
    180deg,
    color(display-p3 0.0836 0.045 0.1621) 0.02%,
    color(display-p3 0.0157 0.0078 0.0314) 86.84%
  );
  position: relative;
  @media only screen and (max-height: 880px) {
    padding-top: ${({ $hasHeader }) => $hasHeader && '6.6rem'};
    align-items: center;
  }

  @media only screen and (max-width: 500px) {
    padding-top: ${({ $hasHeader }) => $hasHeader && '6.6rem'};
    align-items: flex-start;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: ${({ $hasHeader }) => !$hasHeader && 'center'};
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BackContainer = styled.div`
  position: absolute;
  top: 0rem;
  left: 0.7rem;
  cursor: pointer;
  width: 3.2rem !important;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoutContainer = styled.div`
  border-radius: 12px;
  border: 1px solid rgb(67, 33, 129);
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12rem;
  height: 4rem;
  cursor: pointer;
  margin-left: 4rem;

  @media only screen and (max-width: 600px) {
    background: transparent;
    border: none;
    border-radius: 0;
    width: 4rem;
    height: 4rem;
    padding: 0;
    margin-left: unset;
  }
`;

export const LogoutText = styled(Text14Light)`
  color: #d0b3ff;
  opacity: 0.7;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Header = styled.div`
  height: 6.6rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1.6rem;
  border-bottom: 1px solid #1d1229;

  @media only screen and (max-width: 600px) {
    padding-inline: 0;
    margin-left: 1.6rem;
  }
`;
