import styled from 'styled-components';
import { Text20Regular } from '../../utils/Text/Text.styles';

export const Card = styled.div`
  border-radius: 17px;
  border: 1px solid #230e45;
  display: flex;
  gap: 1.2rem;
  padding: 1.2rem;
  align-items: center;
  width: 37rem;

  @media only screen and (max-width: 1100px) {
    width: 25rem;
  }

  @media only screen and (max-width: 980px) {
    width: 20rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4rem;
  width: 100%;
`;

export const EarningContainer = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;

export const DollarSign = styled(Text20Regular)`
  color: #c99aff;
`;
