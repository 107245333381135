import styled from 'styled-components';

export const PageContainer = styled.div`
  padding: 2.4rem 2.2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
  flex-grow: 1;
`;

export const StatisticContainer = styled.div`
  display: flex;
  gap: 1.6rem;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ChartContainer = styled.div`
  border-radius: 17px;
  border: 1px solid #230e45;
  background: linear-gradient(168deg, #130825 16.67%, #090313 95.1%);
  height: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media only screen and (max-width: 600px) {
    height: unset;
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Line = styled.div`
  width: 1px;
  background: #230e45;
  height: 100%;

  @media only screen and (max-width: 600px) {
    height: 1px;
    width: 100%;
  }
`;

export const ChartContent = styled.div`
  display: flex;
  gap: 3rem;
  width: 100%;
  height: 100%;

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const AnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.2rem;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
