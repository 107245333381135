import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import { Text20Bold } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 0 1.6rem 3rem 1.6rem;
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 42rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 500px) {
    flex: 1;
  }
`;

export const InputsRow = styled.div`
  display: flex;
  gap: 1.6rem;

  > :first-child {
    flex: 1;
  }

  > :nth-child(2) {
    flex: 1;
  }
`;

export const StyledButton = styled(Button)`
  margin-inline: auto;
  margin-top: 3.2rem;
  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4rem;
`;

export const BackContainer = styled.div`
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Text20Bold)`
  line-height: 22px;
  letter-spacing: -0.408px;
`;

export const EmptyDiv = styled.div`
  width: 3.2rem;
`;
