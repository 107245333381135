import styled from 'styled-components';
import { Button } from '../../../components/utils/Button/Button';
import { Text18Regular } from '../../../components/utils/Text/Text.styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.4rem;
  overflow: auto;
  max-height: 100%;
  padding: 0 0 3rem 0;

  svg {
    min-height: 3.9rem;
  }

  @media only screen and (max-width: 500px) {
    height: 100%;
    padding: 2rem 1.6rem 3rem 1.6rem;
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 42rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 3.4rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
  }
`;

export const QuestionnaireSection = styled.div`
  width: 100%;
`;

export const SectionTitle = styled(Text18Regular)`
  margin-bottom: 1.6rem;
`;
