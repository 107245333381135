import React from 'react';
import { Card, CardContent, DollarSign, EarningContainer } from './AnalyticsCard.styles';
import { Text16Bold, Text22Bold } from '../../utils/Text/Text.styles';
import { VALUE_OF_ONE_STAR_IN_USD } from '../../../utils/constants';

const AnalyticsCard = ({ earnings, icon, title }) => {
  return (
    <Card>
      {icon}
      <CardContent>
        <Text16Bold>{title}</Text16Bold>
        <EarningContainer>
          <DollarSign>$</DollarSign>
          <Text22Bold>{(earnings * VALUE_OF_ONE_STAR_IN_USD).toFixed(2)}</Text22Bold>
        </EarningContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
