import styled from 'styled-components';
import { Button } from '../utils/Button/Button';
import { Text13Regular, Text14Light } from '../utils/Text/Text.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.4rem;
  height: 100%;
`;

export const Header = styled.div`
  padding: 1.4rem 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NumOfMediaText = styled(Text13Regular)`
  letter-spacing: -0.408px;
  color: #a080db;
`;

export const HeaderText = styled(Text14Light)`
  opacity: 0.4;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  gap: 4px;

  margin: 1.6rem 0 4rem 0;
  padding: 0.8rem;
  width: 100%;
  max-width: 90rem;
  min-height: 30rem;
  max-height: 46rem;
  overflow: auto;

  border-radius: 16px;
  border: 1px solid #2c1655;
  background: #150f23;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  width: 35rem;

  @media only screen and (max-width: 500px) {
    margin-top: auto;
    width: 100%;
  }
`;
