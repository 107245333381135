import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';

export const Container = styled.div`
  .rs-picker-input-group {
    background-color: transparent;
    border: 1px solid #2c1655;

    &:hover {
      border-color: #2c1655;
    }

    svg {
      color: white;
    }
  }

  .rs-input-group-addon {
    display: none;
  }

  .rs-picker-input-group {
    background-color: transparent;
    border: 1px solid #2c1655;

    &:hover {
      border-color: #2c1655;
    }

    svg {
      color: white;
    }
  }

  .rs-input-group {
    &:focus-within {
      outline: none;
      border-color: #2c1655;
    }
  }

  .rs-input-group-focus {
    border: 1px solid #2c1655;
    outline: none;

    &:hover {
      border-color: #2c1655;
    }
  }

  .rs-input {
    background-color: transparent;
    color: white;
    font-weight: 300;

    &::placeholder {
      color: white;
    }
  }
`;
