import styled from 'styled-components';
import { Text14Light } from '../../Text/Text.styles';
import { theme } from '../../../../styles/constants';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.8rem;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
`;

export const LabelText = styled(Text14Light)`
  margin-bottom: 0.6rem;
  margin-left: 0.2rem;
  text-transform: capitalize;
`;

export const StyledInput = styled.input`
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 300;
  color: ${theme.colors.white};
  background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36;
  height: 5.6rem;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #2c1655;
  padding: 1.4rem 1.6rem;
  padding-right: ${props => (props.type === 'password' || props.$isShown || props.$rightIcon ? '5rem' : '0')};
  padding-left: ${props => (props.$leftIcon ? '3.2rem' : '1.2rem')};
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  &::placeholder {
    color: #fff;
  }
`;

export const StyledTextarea = styled.textarea`
  font-size: 1.5rem;
  line-height: normal;
  font-weight: 300;
  color: ${theme.colors.white};
  background: linear-gradient(351deg, rgba(0, 0, 0, 0.2) 7.02%, rgba(0, 0, 0, 0.2) 92.98%), #1c0d36;
  height: 14rem;
  width: 100%;
  border: 1px solid #2c1655;
  border-radius: 12px;
  padding: 1.4rem 1.6rem;
  box-shadow: none;
  outline: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};
  resize: none;
`;

export const VisibilityButton = styled.div`
  position: absolute;
  border: none;
  cursor: pointer;
  background: transparent;
  top: ${props => (props.$labelExists ? '4rem' : '1.6rem')};
  right: 16px;
  height: 2.4rem;

  > svg {
    fill: #bc9eff;
  }
`;

export const LeftIconContainer = styled.div`
  position: absolute;
  height: 2.4rem;
  top: ${props => (props.$labelExists ? '4.8rem' : '1rem')};
  left: 1.4rem;
`;

export const RightIconContainer = styled.div`
  position: absolute;
  height: 2.4rem;
  top: ${props =>
    props.$labelExists ? (props.$rightIconLoader ? '3rem' : '4rem') : props.$rightIconLoader ? '0.2rem' : '1.2rem'};
  right: 2rem;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const Loader = styled.img`
  width: 4.4rem;
`;
